import CryptoJS from 'crypto-js';

export function doEncode(code){
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(code));
}

export function doDecode(code){
    return CryptoJS.enc.Base64.parse(code).toString(CryptoJS.enc.Utf8);
}


